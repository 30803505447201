import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as actionCreators from '../../actions';
import { sendAccessToken } from '../../queries/mutations';
import { getLangDefinition } from '../../utils/helpers';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import Drawer from '../Drawer';
import AdblockerDetector from '../Banner/BlockerDetector';
import FooterPlaceholder from '../Placeholders/FooterPlaceholder';
import MenuPlaceholder from '../Placeholders/MenuPlaceholder';
import Loader from '../Loader';
import AuthModal from '../AuthModal';

import '../../styles/layout.scss';

const Header = dynamic(() => import('../Header'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const Footer = dynamic(() => import('../Footer'), {
  loading: FooterPlaceholder,
  ssr: false
});

const Menu = dynamic(() => import('../Menu'), {
  loading: MenuPlaceholder,
  ssr: false
});

const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible,
    isAuthVisible: state.authModal.isVisible
  };
};

const Layout = ({
  children,
  isVisible,
  toggleDrawer,
  setBlockerDetector,
  isAuthVisible,
  toggleAuthModal
}) => {
  const {
    i18n: { language }
  } = useTranslation();
  const allClasses = classNames(
    'main-content',
    `locale-${getLangDefinition(language)}`
  );
  const router = useRouter();
  const closeAuthModalHandler = () => {
    toggleAuthModal(false);

    router.push({
      pathname: router.pathname,
      hash: '',
      query: router.query
    });
  };
  const accessToken = router.query.access_token;
  const [mutateToken, { loading }] = useMutation(sendAccessToken);

  useMemo(() => {
    if (accessToken) {
      mutateToken({
        variables: { accessToken }
      });
    }
  }, []);

  useEffect(() => {
    if (router.asPath.match('#authentication')) {
      toggleAuthModal();
    }

    router.events.on('hashChangeComplete', url => {
      if (url.match('#authentication')) {
        toggleAuthModal(true);
      } else {
        toggleAuthModal(false);
      }
    });
  }, []);

  if (loading) return null;

  return (
    <div className={allClasses}>
      <Header />
      <Drawer
        isVisible={isVisible}
        toggleDrawer={toggleDrawer}
        additionalClass="drawer-nav"
      >
        {isVisible && <Menu toggleDrawer={toggleDrawer} />}
      </Drawer>
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      <Footer />
      {isAuthVisible && (
        <AuthModal
          isOpen={isAuthVisible}
          modalId="auth"
          closeHandler={closeAuthModalHandler}
        />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.object,
  toggleDrawer: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isAuthVisible: PropTypes.bool,
  setBlockerDetector: PropTypes.func
};

export default connect(mapStateToProps, actionCreators)(Layout);
